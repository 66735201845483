<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import type { BoxVariants, Element } from '../types'

    interface Props extends HTMLAttributes<HTMLElement> {
        element?: Element
        variant?: BoxVariants
        children: Snippet
    }

    const {
        class: classNames = '',
        element = 'div',
        variant = 'white',
        children,
        ...rest
    }: Props = $props()
</script>

<svelte:element this={element} class="box box-{variant} {classNames}" {...rest}>
    {@render children()}
</svelte:element>

<style global lang="postcss">
    .box {
        padding: theme('spacing.6');
        border-radius: theme('borderRadius.DEFAULT');
    }

    .box-white {
        color: theme('colors.nmd-700');
        background-color: theme('colors.white');
    }

    .box-light {
        color: theme('colors.nmd-700');
        background-color: theme('colors.gray-50');
    }

    .box-dark {
        color: theme('colors.nmd-50');
        background-color: theme('colors.nmd-700');
    }
</style>
