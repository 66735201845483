<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    interface Props extends HTMLAttributes<HTMLElement> {
        class?: string
        href?: string
        id?: string
        open?: boolean
        disabled?: boolean
        setActiveTab?: (id: string) => void
        children: Snippet
    }

    const {
        class: classNames = '',
        href = '',
        id = '',
        open = false,
        disabled = false,
        setActiveTab = () => {},
        children,
    }: Props = $props()
</script>

{#if href}
    <a
        class="tab-item-button {classNames} {disabled ? 'disabled' : ''}"
        class:active={open}
        {href}
    >
        {@render children()}
    </a>
{:else}
    <button
        id={`tab-${id}`}
        class="tab-item-button {classNames} {disabled ? 'disabled' : ''}"
        class:active={open}
        aria-controls={`panel-${id}`}
        aria-selected={open}
        onclick={() => setActiveTab(id)}
        role="tab"
    >
        {@render children()}
    </button>
{/if}

<style global lang="postcss">
    .tab-item-button {
        display: flex;
        flex-shrink: 0;
        gap: theme('spacing.2');
        align-items: center;
        padding: 0.5rem 0.375rem;
        font-weight: theme('fontWeight.bold');
        color: theme('colors.gray-400');
        border-bottom: theme('borderWidth.2') solid transparent;
        transition: theme('transitionDuration.DEFAULT')
            theme('transitionTimingFunction.DEFAULT')
            theme('transitionProperty.colors');

        @screen lg {
            padding: theme('spacing.4');
        }

        &:hover {
            color: theme('colors.gray-600');
            border-bottom-color: theme('colors.gray-600');
        }

        &.active {
            color: theme('colors.primary');
            border-bottom-color: theme('colors.primary');
        }

        &.disabled {
            color: theme('colors.gray-300');
            pointer-events: none;
            cursor: default;
        }
    }
</style>
