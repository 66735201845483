<script lang="ts">
    import { mdiClose } from '@mdi/js'
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'
    import { fade, slide } from 'svelte/transition'

    import Flex from '../atoms/Flex.svelte'
    import Icon from '../atoms/Icon.svelte'
    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLDivElement> {
        isOpen?: boolean
        useCloseButton?: boolean
        heading?: Snippet
        children: Snippet
    }

    let {
        class: classNames = '',
        isOpen = $bindable(false),
        useCloseButton = false,
        heading,
        children,
    }: Props = $props()

    function closeModalOnEsc(e: KeyboardEvent) {
        if (e.key !== 'Escape') {
            return
        }

        isOpen = false
    }
    const classes = $derived(concatClasses(['modal', classNames]))
</script>

<svelte:window onkeydown={closeModalOnEsc} />

{#if isOpen}
    <div class={classes} aria-modal="true" role="dialog">
        <!-- svelte-ignore a11y_no_static_element_interactions -->
        <div
            class="modal-backdrop"
            onkeypress={() => (isOpen = false)}
            transition:fade
        ></div>
        <div class="modal-dialog" transition:slide>
            <div class="modal-content">
                {#if useCloseButton || heading}
                    <Flex
                        class="mb-4 px-6 pt-6 text-primary-700"
                        alignItems="center"
                    >
                        {#if heading}
                            {@render heading()}
                        {/if}

                        {#if useCloseButton}
                            <button
                                class="ml-auto"
                                aria-label="Close modal"
                                onclick={() => (isOpen = false)}
                            >
                                <Icon icon={mdiClose} size={24} />
                            </button>
                        {/if}
                    </Flex>
                {/if}
                <div class="modal-body">
                    {@render children()}
                </div>
            </div>
        </div>
    </div>
{/if}

<style global lang="postcss">
    .modal {
        position: fixed;
        inset: 0;
        z-index: theme('zIndex.modal');
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .modal-backdrop {
        position: fixed;
        inset: 0;
        z-index: theme('zIndex.modalBackdrop');
        background-color: rgba(theme('colors.black'), 0.6);
        filter: blur(0.5rem);
        backdrop-filter: blur(0.5rem);
    }

    .modal-dialog {
        position: relative;
        z-index: theme('zIndex.modal');
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(100% - theme('spacing.16'));
        margin: theme('spacing.8') auto;
    }

    .modal-content {
        position: relative;
        width: 100%;
        overflow: hidden;
        text-align: left;
        border-radius: theme('borderRadius.lg');
        background-color: theme('colors.white');
        box-shadow: theme('boxShadow.xl');

        @screen sm {
            max-width: theme('maxWidth.xl');
            margin-top: theme('spacing.8');
            margin-bottom: theme('spacing.8');
        }
    }
</style>
