<script lang="ts">
    import type { HTMLAttributes } from 'svelte/elements'

    interface Props extends HTMLAttributes<SVGElement> {
        icon: string
        size?: number
    }

    const { class: classNames = '', icon, size = 16, ...rest }: Props = $props()
</script>

<svg
    class={classNames}
    fill="currentColor"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    {...rest}
>
    <path d={icon} />
</svg>
