<script lang="ts">
    import { mdiChevronDown } from '@mdi/js'
    import { clickoutside } from '@svelte-put/clickoutside'
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'
    import { fade } from 'svelte/transition'

    import Box from '../atoms/Box.svelte'
    import Icon from '../atoms/Icon.svelte'
    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLDivElement> {
        active?: boolean
        useOnlyClick?: boolean
        link: Snippet
        children: Snippet
    }

    const {
        class: classNames = '',
        active = false,
        useOnlyClick = false,
        link,
        children,
    }: Props = $props()

    let open = $state(false)
    function toggleDropdown() {
        open = !open
    }
    function openDropdown() {
        if (useOnlyClick) {
            return
        }

        open = true
    }
    function closeDropdown() {
        if (useOnlyClick) {
            return
        }

        open = false
    }

    const classes = $derived(concatClasses(['dropdown', classNames]))
</script>

<!-- svelte-ignore a11y_no_static_element_interactions -->
<div
    class={classes}
    onblur={() => closeDropdown()}
    onclickoutside={() => (open = false)}
    onfocus={() => openDropdown()}
    onmouseout={() => closeDropdown()}
    onmouseover={() => openDropdown()}
    use:clickoutside
>
    <button
        class={`dropdown-link link hover:bg-transparent ${
            active ? 'active' : ''
        }`}
        data-dropdown="true"
        onclick={toggleDropdown}
        onfocus={() => openDropdown()}
        onmouseover={() => openDropdown()}
        type="button"
    >
        {@render link()}
        <Icon
            class={`dropdown-icon ${open ? 'active' : ''}`}
            icon={mdiChevronDown}
            size={16}
        ></Icon>
    </button>

    {#if open}
        <div transition:fade={{ duration: 150 }}>
            <Box class="dropdown-menu">
                {@render children()}
            </Box>
        </div>
    {/if}
</div>

<style global lang="postcss">
    .dropdown {
        position: relative;
    }

    .dropdown-menu {
        position: absolute;
        top: calc(100% + 1rem);
        z-index: theme('zIndex.40');
        padding: theme('spacing.4');
    }

    .dropdown-link {
        display: flex;
        gap: theme('spacing.1');
        align-items: center;
    }

    .dropdown-icon {
        transition: transform 0.15s ease-in-out;
    }

    .dropdown-icon.active {
        transform: rotate(180deg);
    }
</style>
