<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    interface Props extends HTMLAttributes<HTMLDivElement> {
        children: Snippet
    }

    const { class: classNames = '', children, ...rest }: Props = $props()
</script>

<div class="container {classNames}" {...rest}>
    {@render children()}
</div>

<style lang="postcss">
    .container {
        @media screen(sm) {
            max-width: theme('container.maxWidths.sm');
        }

        @media screen(md) {
            max-width: theme('container.maxWidths.md');
        }

        @media screen(lg) {
            max-width: theme('container.maxWidths.lg');
        }

        @media screen(xl) {
            max-width: theme('container.maxWidths.xl');
        }
    }
</style>
